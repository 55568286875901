<ng-http-loader [backgroundColor]="'#047bf8'" [spinner]="spinkit.skWave" [debounceDelay]="100" [minDuration]="300" [filteredUrlPatterns]="utility.getExludedLoadingUrls()">
</ng-http-loader>
    <div class="element-wrapper" style="height: 100vh !important">
        <div class="element-box col-lg-12 col-md-12" style="margin: auto; padding: 0; height: auto !important">
            <div *ngIf="(objInFromCustComp.isPayLink && objInFromCustComp.isPayLinkValid) " style="height: auto !important; justify-content: center;">
                <div class="row py-5" *ngIf="objInFromCustComp.isPayLinkManualMustVerify" style="max-width: 800px; margin: auto;">
                    <h4 class="modal-title text-center"  *ngIf="!objPaymentLink.thirdPartyBankVerification" style="width: 100%;">Verify Your Bank Account</h4>
                    <h4 class="modal-title text-center" *ngIf="objPaymentLink.thirdPartyBankVerification" style="width: 100%;">Success!</h4>
                    <p style="padding-top: 10px;" *ngIf="!objPaymentLink.thirdPartyBankVerification">
                        We have initiated 2 deposits into your <b>{{objBankInfo.name || objExistUser.bank}}</b> account ending in <b>{{getLastDigitAccount() || objExistUser.account}}</b>
                        and you should see these over the next 4 days. Once you see the
                        deposits in your bank account you can login to your dashboard to confirm the amounts
                    </p>
                    <p style="padding: 0 9%;" *ngIf="objPaymentLink.thirdPartyBankVerification">
                        Thank You for Verifying Your Bank Account
                    </p>
                    <p style="padding: 0 9%;" *ngIf="objPaymentLink.thirdPartyBankVerification">
                        Your <b>{{objBankInfo.name || objExistUser.bank}}</b> account ending in <b>{{getLastDigitAccount() || objExistUser.account}}</b> has been successfully added to your account
                    </p>

                    <div class="text-center" style="width: 100%;">
                        <button style="padding: 7px 25px;" class="btn btn-primary btn-rounded" type="button" (click)="loginCreatedUser()">
                            GO TO DASHBOARD <i class="fa fa-long-arrow-right"></i>
                        </button>
                    </div>
                </div>
                <div class="row d-md-block d-lg-block d-sm-block d-block d-xl-flex new_flex mx-0 py-6" style="height: 100vh !important" *ngIf="!objInFromCustComp.isPayLinkManualMustVerify">
                    <div class="col-lg-6 col-md-12 col-sm-12 py-3" style="background-color: white;">
                        <div class="mx-auto justify-content-center py-5" style="width: 85%; height: 90%;">
                            <div class="row mx-0 text-center" style="align-items:center;">
                                <div *ngIf="objInFromCustComp.objPaymentLink.link.logo">
                                    <img alt="" style="max-width: 150px; max-height: 150px;" src="{{objInFromCustComp.objPaymentLink.link.logo}}">
                                </div>
                                <div class="row mx-0" *ngIf="!objInFromCustComp.objPaymentLink.link.logo">
                                    <img alt="" style="max-width: 75px;" src="assets/img/colored.png"> <h3 class="text-bold px-3 mb-0" style="color: #5073EF;align-self: center;font-weight: 700;">PAYNOTE</h3>
                                </div>
                            </div>
                            <div class="row mx-0 py-3">
                                <h5>
                                    {{objInFromCustComp.objPaymentLink.link.slogan || 'Verified ACH Payments'}}
                                </h5>
                            </div>
                            <div class="row mx-0 mt-5">
                                <h5>
                                    {{objInFromCustComp.objPaymentLinkForm.description || 'Description'}}
                                </h5>
                            </div>
                            <div class="row mx-0 mt-2">
                                <h1>
                                    {{(objInFromCustComp.objPaymentLinkForm.amount | currency) || ('$0.00')}}
                                </h1>
                                <p style="align-items: flex-end;display: flex;margin-left:10px;">
                                    <span *ngIf="objInFromCustComp.objPaymentLinkForm.billing_cycle">per {{objInFromCustComp.objPaymentLinkForm.billing_cycle}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="d-flex mx-auto mt-2 justify-content-between" style="width: 85%; font-size: 16px;">
                            <div class="col-7 p-0">
                                <a href="http://seamlesschex.com/paynote">Powered by PAYNOTE</a>
                            </div>
                            <div class="col-5 text-right p-0">
                                <a class="mx-3" href="https://www.seamlesschex.com/terms-of-service">Terms</a>
                                <a class="mx-3" href="https://www.seamlesschex.com/privacy-policy">Privacy</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12" style="background-color: #5073EF; color: white;">
                        <div class="mx-auto justify-content-center middle-body py-5 payment-complete" style="width: 85%; height: 100%; padding-top: 40% !important; padding-bottom: 40% !important;" *ngIf="(objInFromCustComp.isPayLinkPlaidVerified || objInFromCustComp.isPayLinkSuccessSendMoney)">
                            <div class="text-center" style="align-items:center; padding-bottom: 10px;">
                                <img class="filter-white" src="/assets/img/check-accept-approve.svg" style="max-width: 75px;">
                            </div>
                            <div class="text-center">
                                <h1 style="color: white;line-height: 1.4; font-weight: 300;">
                                    Payment Complete
                                </h1>
                            </div>
                            <div class="text-center">
                                <h3 style="color: white; line-height: 1.4; font-weight: 300;">{{objInFromCustComp.objPaymentLinkForm.amount | currency}} has been transferred to {{objInFromCustComp.objPaymentLink.rec_name}}</h3>
                            </div>
                        </div>
                        <div class="d-flex new_padding mx-auto justify-content-center middle-body" *ngIf="!(objInFromCustComp.isPayLinkPlaidVerified || objInFromCustComp.isPayLinkSuccessSendMoney)" style="width: 65%; padding-bottom: 10px; height: 90%;" [ngStyle]="{'padding-top' : getPaddingTop()}">
                            <form name="form" (ngSubmit)="f.form.valid && submitBillLink()" #f="ngForm" autocomplete="nope">
                                <p class="pay-link-text-body"><b>Step 1:</b> Enter the fields below:</p>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="form-group" [ngClass]="{ 'has-error has-danger': !sndr_name.valid && (sndr_name.dirty || sndr_name.touched) }">
                                            <label for="billLinkName">Full Name</label>
                                            <input autocomplete="off" pattern="(^[0-9a-zA-Z][a-zA-Z0-9\.\'\’\&\,\- ]+[0-9a-zA-Z\.\,]+$)" id="billLinkName" class="form-control" placeholder="Account Holder Name" type="text" name="sndr_name" required [(ngModel)]="objInFromCustComp.objPaymentLinkForm.sndr_name" #sndr_name="ngModel">
                                            <div *ngIf="!sndr_name.valid && (sndr_name.dirty || sndr_name.touched) && sndr_name.errors.required" class="help-block form-text with-errors form-control-feedback">Name is required
                                            </div>
                                            <div *ngIf="!sndr_name.valid && (sndr_name.dirty || sndr_name.touched) && sndr_name.errors.pattern" class="help-block form-text with-errors form-control-feedback">Invalid Name
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="form-group" [ngClass]="{ 'has-error has-danger': !sndr_email.valid && (sndr_email.dirty || sndr_email.touched) }">
                                            <label for="billLinkEmail">Email</label>
                                            <input autocomplete="off" id="billLinkEmail" class="form-control" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" placeholder="Email on file with bank" type="email" name="sndr_email" required [(ngModel)]="objInFromCustComp.objPaymentLinkForm.sndr_email" #sndr_email="ngModel">
                                            <div *ngIf="!sndr_email.valid && (sndr_email.dirty || sndr_email.touched) && sndr_email.errors.required" class="help-block form-text with-errors form-control-feedback">Email is required
                                            </div>
                                            <div *ngIf="!sndr_email.valid && (sndr_email.dirty || sndr_email.touched) && sndr_email.errors.pattern" class="help-block form-text with-errors form-control-feedback">Invalid Email
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="!objInFromCustComp.objPaymentLink.link.amount">
                                        <div class="form-group" [ngClass]="{ 'has-error has-danger': !amount.valid && (amount.dirty || amount.touched) }">
                                            <label for="billLinkAmount">Amount</label>
                                            <input autocomplete="off" id="billLinkAmount" class="form-control" placeholder="$" type="text" name="amount" currencyMask [(ngModel)]="objInFromCustComp.objPaymentLinkForm.amount" #amount="ngModel" required min="{{objInFromCustComp.rulesForm.minSendAmount}}"
                                                [disabled]="objInFromCustComp.rulesForm.disableInputSendAmount">
                                            <div *ngIf="!amount.valid && (amount.dirty || amount.touched)" class="help-block form-text with-errors form-control-feedback">Amount is required
                                            </div>
                                            <div *ngIf="!amount.valid && (amount.dirty || amount.touched) && amount.errors.min" class="help-block form-text with-errors form-control-feedback">
                                                The minimum amount you can send is {{objInFromCustComp.rulesForm.minSendAmount | currency}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="!objInFromCustComp.objPaymentLink.link.description">
                                        <div class="form-group" [ngClass]="{ 'has-error has-danger': !description.valid && (description.dirty || description.touched) }">
                                            <label for="billLinkDescription">Description <i class="fa fa-question-circle"
                                                    placement="top"
                                                    ngbTooltip="{{messages.get('SEND_RECEIVE_DESCRIPTION_MAX_LENGTH')}}"></i></label>
                                            <input id="billLinkDescription" maxlength="75" class="form-control" placeholder="What is the payment for?" type="text" name="description" required [(ngModel)]="objInFromCustComp.objPaymentLinkForm.description" #description="ngModel">
                                            <div *ngIf="!description.valid && (description.dirty || description.touched)" class="help-block form-text with-errors form-control-feedback">Description is required</div>
                                        </div>
                                    </div>
                                    <!---->
                                    <p style="width: 100%; padding-right: 10px; padding-left: 10px;" *ngIf="objInFromCustComp.objPaymentLinkForm.billing_cycle">
                                        <!-- Sender has requested a {{utility.getFrequencyString( objInFromCustComp.objPaymentLinkForm.billing_cycle )}} recurring check.
                                        To send a one time payment unselect Recurring button below. -->
                                    </p>
                                </div>
                                <p class="pay-link-text-body mt-3 "><b>Step 2:</b> Click the  "{{ objInFromCustComp.objPaymentLink.link.button || 'Send Payment' }}" button to securely link your bank account using your online banking credentials. This process is both free and ensures the encryption and verification of your bank information through Plaid.</p>
                                <div class="buttons-w form-group button_submit_request_invoice text-center" style="margin-top: 0px;">
                                    <button id="sendCheckButton" style="background-color: white; color: black; font-weight: 500 !important; height: 40px; " type="submit" [disabled]="!f.form.valid || isLoading" class="btn btn-lg">
                                        <span style="opacity: 1 !important;">{{objInFromCustComp.objPaymentLink.link.button || 'Send Payment'}}</span>
                                    </button>
                                </div>
                            </form>
                            <br>
                            <br>
                        </div>
                        <div *ngIf="!(objInFromCustComp.isPayLinkPlaidVerified || objInFromCustComp.isPayLinkSuccessSendMoney) && objInFromCustComp.objPaymentLink.on_demand_auth" class="text-center w-75 mx-auto pb-3 bottom-text">
                        I agree that future payments to {{objInFromCustComp.objPaymentLink.rec_name}} will be processed by Paynote Payment System from the selected account above. In Order to cancel this authorization, I will reach out to {{objInFromCustComp.objPaymentLink.rec_name}}.
                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="objInFromCustComp.isPayLink && !objInFromCustComp.isPayLinkValid">
                <div class="row">
                    <div class="text-center col-lg-12" style="margin: auto;">
                        <h4 [innerHTML]="messages.get('PAYMENT_LINK_WAS_DISABLED')"></h4>
                    </div>
                </div>
            </div>
            <div *ngIf="!objInFromCustComp.isPayLink && isInvoiceHold">
                <div class="row">
                    <div class="text-center col-lg-12" style="margin: auto;">
                        <h4 [innerHTML]="messages.get('DECLINE_DONOT_HAVE_FUNDS')"></h4>
                    </div>
                </div>
            </div>
            <!--   /////////////////////   Block Invoice   //////////////////////////////-->
            <div *ngIf="isInvoicePending && isInvoiceValid && objInFromCustComp.isInvoice" style="height: auto !important; justify-content: center;">
                <!-- <div class="row py-5" *ngIf="objInFromCustComp.isPayLinkManualMustVerify" style="max-width: 800px; margin: auto;">
                    <h4 class="modal-title text-center"  *ngIf="!objPaymentLink.thirdPartyBankVerification" style="width: 100%;">Verify Your Bank Account</h4>
                    <h4 class="modal-title text-center" *ngIf="objPaymentLink.thirdPartyBankVerification" style="width: 100%;">Success!</h4>
                    <p style="padding-top: 10px;" *ngIf="!objPaymentLink.thirdPartyBankVerification">
                        We have initiated 2 deposits into your <b>{{objBankInfo.name || objExistUser.bank}}</b> account ending in <b>{{getLastDigitAccount() || objExistUser.account}}</b>
                        and you should see these over the next 4 days. Once you see the
                        deposits in your bank account you can login to your dashboard to confirm the amounts
                    </p>
                    <p style="padding: 0 9%;" *ngIf="objPaymentLink.thirdPartyBankVerification">
                        Thank You for Verifying Your Bank Account
                    </p>
                    <p style="padding: 0 9%;" *ngIf="objPaymentLink.thirdPartyBankVerification">
                        Your <b>{{objBankInfo.name || objExistUser.bank}}</b> account ending in <b>{{getLastDigitAccount() || objExistUser.account}}</b> has been successfully added to your account
                    </p>

                    <div class="text-center" style="width: 100%;">
                        <button style="padding: 7px 25px;" class="btn btn-primary btn-rounded" type="button" (click)="loginCreatedUser()">
                            GO TO DASHBOARD <i class="fa fa-long-arrow-right"></i>
                        </button>
                    </div>
                </div> -->
                <div class="row d-md-block d-lg-block d-sm-block d-block d-xl-flex new_flex mx-0 py-6" style="height: 100vh !important">
                    <div class="col-lg-6 col-md-12 col-sm-12 py-3" style="background-color: white;">
                        <div class="mx-auto justify-content-center py-5" style="width: 85%; height: 90%;">
                            <div class="row mx-0 text-center" style="align-items:center;">
                                <div *ngIf="haveLogo()">
                                    <img alt="" style="max-width: 150px; max-height: 150px;" [src]="getLogo()">
                                </div>
                                <div class="row mx-0" *ngIf="!haveLogo()">
                                    <img alt="" style="max-width: 75px;" src="assets/img/colored.png"> <h3 class="text-bold px-3 mb-0" style="color: #5073EF;align-self: center;font-weight: 700;">PAYNOTE</h3>
                                </div>
                            </div>
                            <div class="row mx-0 py-3">
                                <h5>
                                    {{getTitle() || 'Verified ACH Payments'}}
                                </h5>
                            </div>
                            <div class="row mx-0 mt-5">
                                <h5>
                                    {{objInvoice.description || 'Description'}}
                                </h5>
                            </div>
                            <div class="row mx-0 mt-2">
                                <h1>
                                    {{(objInvoice.amount | currency) || ('$0.00')}}
                                </h1>
                                <p style="align-items: flex-end;display: flex;margin-left:10px;">
                                    <span *ngIf="objInvoice.billing_cycle">per {{objInvoice.billing_cycle}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="d-flex mx-auto mt-2 justify-content-between" style="width: 85%; font-size: 16px;">
                            <div class="col-7 p-0">
                                <a href="http://seamlesschex.com/paynote">Powered by PAYNOTE</a>
                            </div>
                            <div class="col-5 text-right p-0">
                                <a class="mx-3" href="https://www.seamlesschex.com/terms-of-service">Terms</a>
                                <a class="mx-3" href="https://www.seamlesschex.com/privacy-policy">Privacy</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12" style="background-color: #5073EF; color: white;">
                        <div class="mx-auto justify-content-center middle-body py-5 payment-complete" style="width: 85%; height: 100%; padding-top: 40% !important; padding-bottom: 40% !important;" *ngIf="(objInFromCustComp.isPayLinkPlaidVerified || objInFromCustComp.isPayLinkSuccessSendMoney)">
                            <div class="text-center" style="align-items:center; padding-bottom: 10px;">
                                <img class="filter-white" src="/assets/img/check-accept-approve.svg" style="max-width: 75px;">
                            </div>
                            <div class="text-center">
                                <h1 style="color: white;line-height: 1.4; font-weight: 300;">
                                    Payment Complete
                                </h1>
                            </div>
                            <div class="text-center">
                                <h3 style="color: white; line-height: 1.4; font-weight: 300;">{{objInvoice.amount | currency}} has been transferred to {{objInvoice.rec_name}}</h3>
                            </div>
                        </div>
                        <div class="d-flex new_padding mx-auto justify-content-center middle-body" *ngIf="!isInvoiceProcessed && !isInvoiceProcessed && !objInFromCustComp.isPayLink" style="width: 65%; padding-bottom: 10px; height: 90%; padding-top: 20%">
                            <form name="form" #f="ngForm" autocomplete="nope">
                                <p class="pay-link-text-body"><b>Step 1:</b> Enter the fields below:</p>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="form-group" [ngClass]="{ 'has-error has-danger': !sndr_name.valid && (sndr_name.dirty || sndr_name.touched) }">
                                            <label for="billLinkName">Full Name</label>
                                            <input autocomplete="off" disabled pattern="(^[A-Za-z]{2,16})([ ]{0,1})([A-Za-z]{2,16})?([ ]{0,1})?([A-Za-z]{2,16})?([ ]{0,1})?([A-Za-z]{2,16})" id="billLinkName" class="form-control" placeholder="Account Holder Name" type="text" name="sndr_name" required [(ngModel)]="objInvoice.sndr_name" #sndr_name="ngModel">
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label for="billLinkEmail">Email</label>
                                            <input autocomplete="off" disabled id="billLinkEmail" class="form-control" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" placeholder="Email on file with bank" type="email" name="sndr_email" required [(ngModel)]="objInvoice.sndr_email" #sndr_email="ngModel">
                                        </div>
                                    </div>
                                </div>
                                <p class="pay-link-text-body mt-3 "><b>Step 2:</b> Click the  "{{ getButtonLabel() || 'Send Payment' }}" button to securely link your bank account using your online banking credentials. This process is both free and ensures the encryption and verification of your bank information through Plaid.</p>
                                <div class="buttons-w form-group button_submit_request_invoice text-center" style="margin-top: 0px;">
                                    <button id="sendCheckButton" style="background-color: white; color: black; font-weight: 500 !important; height: 40px; " type="submit" [disabled]="isLoading" *ngIf="!isExistUser || !requireToFAuth()" (click)="openModal(depositCheckOnline)" class="btn btn-lg">
                                        <span style="opacity: 1 !important;">{{getButtonLabel() || 'Send Payment'}}</span>
                                    </button>

                                    <button id="sendCheckButton"  style="background-color: white; color: black; font-weight: 500 !important; height: 40px; " type="submit" [disabled]="isLoading" (click)="openModal(verifyPhoneExistUser); sendVerifyCode()" *ngIf="isExistUser && requireToFAuth()" class="btn btn-lg">
                                        <span style="opacity: 1 !important;">{{getButtonLabel() || 'Send Payment'}}</span>
                                    </button>
                                </div>
                            </form>
                            <br>
                            <br>
                        </div>

                    </div>
                </div>
            </div>
            <div *ngIf="isInvoiceProcessed && !objInFromCustComp.isPayLink">
                <h2 class="element-header text-center" style="margin-top: 50px;">Invoice Processed</h2>
                <div class="row">
                    <div class="text-center col-lg-12" style="margin: auto;">
                        <h4 [innerHTML]="messages.get('INVOICE_PROCESSED')"></h4>
                    </div>
                    <div class="clear-block"></div>
                    <br><br><br>
                    <div class="text-center" style="margin: auto; margin-bottom: 40px;">
                        <a style="margin: auto; color: white !important;" class="btn btn-primary btn-rounded" href="/login">
                            GO TO LOGIN <i class="fa fa-long-arrow-right"></i>
                        </a>
                        <a href="/create-password" class="btn btn-outline-primary btn-rounded">Create Password</a>
                    </div>
                </div>
            </div>
            <div *ngIf="isInvoiceCanseled && !objInFromCustComp.isPayLink">
                <h2 class="element-header text-center" style="margin-top: 50px;">Invoice Canceled</h2>
                <div class="row">
                    <div class="text-center col-lg-12" style="margin: auto;">
                        <h4 [innerHTML]="messages.get('INVOICE_CENCELED')"></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>

<ng-template #depositCheckOnline let-c="close" let-d="dismiss">
    <div *ngIf="isExistUser">
        <div class="manual-verification">
            <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="manual-verification-title">
                    <h4 class="modal-title">Confirm Payment</h4>
                </div>
                <div class="modal-body">
                    <div class="manual-verification-title">
                        <p style="padding: 0 9%;margin: 0;"><b>
                            You're sending {{objInvoice.amount | currency:'' :'symbol'}} to {{objInFromCustComp.objPaymentLink.rec_name || objPaymentLink.rec_name}}. The funds will be deducted from your {{objCurAccount.bank}}<span *ngIf="!isTransferFromBalance()"> account ending in {{objCurAccount.account || objCurAccount.mask}}</span>.
                        </b>
                        </p>
                        <button style="margin-top: 10px;" type="button" class="btn btn-primary btn-rounded" *ngIf="lstAccounts.length < 8 && !isTransferFromBalance()" (click)="openModal(selectTypeAddFundingSource, depositCheckOnline)" [disabled]="isLoading">
                            Change Bank Account
                        </button>
                    </div>
                    <br>
                    <div class="form-group" style="margin-bottom: 0px;" *ngIf="lstAccounts.length > 1">
                        <select [disabled]="isTransferFromBalance()" name="userAccount" class="form-control" placeholder="Select Account" [(ngModel)]="objDigitCheck.account_id" #userAccount="ngModel" (change)="selectAnotherBankAccount()" style="width: 100%;">
                            <option *ngFor="let account of lstAccounts; let i = index" value="{{i}}">
                                {{account.bank}}&nbsp;{{account.mask ? '****' : ''}}{{ account.balance ? '$'+account.balance : '' }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="errorService.isError" class="alert alert-danger" role="alert" [innerHTML]=errorService.errorMessage></div>
                    <div *ngIf="errorService.isSuccess" class="alert alert-success" role="alert" [innerHTML]=errorService.successMessage></div>
                    <div *ngIf="isLoading" class="alert alert-warning" role="alert" style="margin-top: 20px;">
                        Please wait. Do not refresh browser page until you will see payment confirmation message. Thank you.
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="slick-prev slick-arrow" type="button" (click)="openBackModal()" [disabled]="isLoading">Cancel</button>
                <button class="slick-next slick-arrow" type="button" [disabled]="isLoading" (click)="sendInvoiceCheck(successReceivedMoney, depositCheckOnline)">Submit</button>
            </div>
        </div>
    </div>

    <div *ngIf="!isExistUser">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <!--  <div class="onboarding-media">
        <img style="width: 310px;" src="assets/img/checkImg.png" class="addFundingSourceImg">
      </div> -->
        <div class="modal-body addFundingSourceBox" style="padding-bottom: 0px;">
            <div class="modal-body">
                <div class="onboarding-content with-gradient">
                    <h4 class="modal-title">{{messages.get('SEND_THE_CHECK_ONLINE')}}</h4>
                    <div>
                        <b>
                            {{messages.get('SENDING_A_DIGITAL_CHECK_TEXT')}}
                        </b>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <button class="btn btn-primary btn-lg btn-block btn-rounded" placement="top" ngbTooltip="Individual Type" type="button" [ngClass]="{ 'btn-success': objDigitCheck.accountType == 'personal' }" (click)="objDigitCheck.accountType = 'personal'">Individual</button>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{messages.get('SEND_CHECK_AS_PERSONAL')}}
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <button class="btn btn-primary btn-lg btn-block btn-rounded" placement="top" ngbTooltip="Business Type" type="button" [ngClass]="{ 'btn-success': objDigitCheck.accountType == 'business' }" (click)="objDigitCheck.accountType = 'business'">Business</button>
                            </div>
                            <div class="form-group">
                                <label>
                                    {{messages.get('SEND_CHECK_AS_BUSINESS')}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="objDigitCheck.accountType != ''">
                        <div class="col-sm-12">
                            <div class="alert alert-warning" role="alert">
                                <span *ngIf="objDigitCheck.accountType == 'business' ">
                                    {{messages.get('SEND_CHECK_DEPOSIT_POPUP_BUSINESS_INFO_TEXT')}}
                                </span>
                                <span *ngIf="objDigitCheck.accountType == 'personal' ">
                                    {{messages.get('SEND_CHECK_DEPOSIT_POPUP_INDIVIDUAL_INFO_TEXT')}}
                                </span>
                                <span *ngIf="objInFromCustComp.objPaymentLink.on_demand_auth">
                                    <br><br>
                                    I agree that future payments to {{objInFromCustComp.objPaymentLink.rec_name}} will be processed by Paynote Payment System from the selected account above. In order to cancel this authorization, I will reach out to {{objInFromCustComp.objPaymentLink.rec_name}}.
                                </span>
                                <br><br>
                                <div class="text-left">
                                    <label class="checkbox-inline" style="font-size: 14px;color: black;">
                                        <input type="checkbox" class="" [(ngModel)]="objDigitCheck.acceptPolicy"
                                            #acceptPolicy="ngModel">&nbsp;&nbsp;
                                        I have read and accept the Paynote
                                        <a href="https://www.seamlesschex.com/terms-of-service/" target="_blank">
                                            <b>Terms of Service</b>
                                        </a> and
                                        <a href="https://www.seamlesschex.com/privacy-policy/" target="_blank">
                                            <b>Privacy Policy</b>
                                        </a>, as well as our partner Dwolla’s
                                        <a href="https://www.dwolla.com/legal/tos/" target="_blank">
                                            <b>Terms of Service</b>
                                        </a> and
                                        <a href="https://www.dwolla.com/legal/privacy/" target="_blank">
                                            <b>Privacy Policy</b>
                                        </a>.
                                    </label>
                                </div>
                                <br>
                                <button class="btn btn-success btn-block btn-rounded" placement="top" ngbTooltip="Account Type Confirm" type="button" [disabled]="!objDigitCheck.acceptPolicy" *ngIf="requireToFAuth()" (click)="onAccept(verifyPhone, depositCheckOnline)">ACCEPT</button>
                                <button class="btn btn-success btn-block btn-rounded" placement="top" ngbTooltip="Account Type Confirm" type="button" [disabled]="!objDigitCheck.acceptPolicy" *ngIf="!requireToFAuth()" (click)="openModal(selectTypeAddFundingSource, depositCheckOnline)">ACCEPT</button>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="text-center pb-1">
                        <a href="https://www.seamlesschex.com/terms-of-service" target="_blank" class="Our-Terms-Conditio p-1">
                            Terms
                        </a>
                        <a href="https://www.seamlesschex.com/privacy-policy" target="_blank" class="Our-Terms-Conditio p-1">
                            Policy
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #successReceivedMoney let-c="close" let-d="dismiss">
    <div class="manual-verification">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="manual-verification-title">
                <h4 class="modal-title">{{messages.get('YOU’RE_ALL_SET')}}</h4>
                <p style="padding: 0 9%;">{{messages.get('START_ACCEPTING_DIGITAL_CHECKS')}}</p>
            </div>
            <div class="modal-body" style="text-align: center;">
                <button style="padding: 7px 25px;" class="btn btn-primary btn-rounded" type="button" (click)="loginCreatedUser()">
                    Setup your profile <i class="fa fa-long-arrow-right"></i>
                </button>
                <br>
                <button style="color: #1071ef;" type="button" class="btn btn-link" (click)="openModal(seeDepositConfirmation)">
                    or, see deposit confirmation
                </button>
            </div>
        </div>
        <div class="modal-footer">
        </div>
    </div>
</ng-template>

<ng-template #seeDepositConfirmation let-c="close" let-d="dismiss">
    <div class="manual-verification">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="manual-verification-title">
                <h4 class="modal-title">Deposit confirmation</h4>
                <p style="padding: 0 9%;">
                    You successfully submitted a check payment of {{objInvoice.amount | currency:'' :'symbol'}}. The money will be withdrawn from
                    <b>{{ !isTransferFromBalance() ? objDigitCheck.bank_name || objExistUser.bank || objCurAccount.bank : objCurAccount.bank }}</b> {{ !isTransferFromBalance() ? 'account ending in' : '' }} <b>{{ getLastDigitAccount() || objExistUser.account || objCurAccount.mask}}</b>                    the next business day.
                    <br><br> {{messages.get('SEND_CHECK_SUCCESS_MERCHANT_RECEIVED_MANEY_TEXT')}}
                </p>
            </div>
            <div class="modal-body" style="text-align: center;">
                <button style="padding: 7px 25px;" class="btn btn-primary btn-rounded" type="submit" (click)="loginCreatedUser()">
                    GO TO DASHBOARD <i class="fa fa-long-arrow-right"></i>
                </button>
            </div>
        </div>
        <div class="modal-footer">
        </div>
    </div>
</ng-template>

<ng-template #seeSendConfirmationForManualVerification let-c="close" let-d="dismiss">
    <div class="manual-verification">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="manual-verification-title">
                <h4 class="modal-title" *ngIf="!objPaymentLink.thirdPartyBankVerification">Verify Your Bank Account</h4>
                <h4 class="modal-title" *ngIf="objPaymentLink.thirdPartyBankVerification">Success!</h4>
                <p style="padding: 0 9%;" *ngIf="!objPaymentLink.thirdPartyBankVerification">
                    We have initiated 2 deposits into your <b>{{objBankInfo.name || objExistUser.bank}}</b> account ending in <b>{{getLastDigitAccount() || objExistUser.account}}</b> and you should see these over the next 4 days. Once you see the deposits
                    in your bank account you can login to your dashboard to confirm the amounts
                </p>
                <p style="padding: 0 9%;" *ngIf="objPaymentLink.thirdPartyBankVerification">
                    Thank You for Verifying Your Bank Account
                </p>
                <p style="padding: 0 9%;" *ngIf="objPaymentLink.thirdPartyBankVerification">
                    Your <b>{{objBankInfo.name || objExistUser.bank}}</b> account ending in <b>{{getLastDigitAccount() || objExistUser.account}}</b> has been successfully added to your account
                </p>
            </div>
            <div class="modal-body" style="text-align: center;">
                <button style="padding: 7px 25px;" class="btn btn-primary btn-rounded" type="submit" (click)="loginCreatedUser()">
                    GO TO DASHBOARD <i class="fa fa-long-arrow-right"></i>
                </button>
            </div>
        </div>
        <div class="modal-footer">
        </div>
    </div>
</ng-template>

<ng-template #verifyPhone let-c="close" let-d="dismiss">
    <div class="manual-verification">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div *ngIf="!isAvailablePhoneVerification()">
            <div class="modal-body">
                <div class="manual-verification-title">
                    <h4 class="modal-title">Verify Email</h4>
                    <p class="text-center" style="padding: 0 9%;"><b>Please enter the six digit code sent to you at: {{this.objInvoice.sndr_email}}</b></p>
                </div>

                <form name="form" class="" autocomplete="nope" [formGroup]="verifyPhoneFormGroup">
                    <div class="form-group verify_code_inputs text-center">
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_1" autocomplete="off" formControlName="pin_part_1" appNumbers id="pin_part_1" (input)="nextDigit(1)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_2" autocomplete="off" formControlName="pin_part_2" appNumbers id="pin_part_2" (input)="nextDigit(2)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_3" autocomplete="off" formControlName="pin_part_3" appNumbers id="pin_part_3" (input)="nextDigit(3)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_4" autocomplete="off" formControlName="pin_part_4" appNumbers id="pin_part_4" (input)="nextDigit(4)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_5" autocomplete="off" formControlName="pin_part_5" appNumbers id="pin_part_5" (input)="nextDigit(5)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_6" autocomplete="off" formControlName="pin_part_6" appNumbers id="pin_part_6">
                        <br>
                        <br>
                        <button style="color: #1071ef!important; text-decoration: underline;" type="button" [disabled]="timerService.isTimerRun" class="btn btn-link" (click)="sendVerifyCode()">
                          Resend&nbsp;{{timerService.getRemainigTime()}}
                        </button>
                        <span style="display: none"><countdown-timer
                          *ngIf="timerService.isTimerRun"
                          [countDownTimerConfig]="timerService.configCD"></countdown-timer>
                        </span>
                    </div>
                    <div *ngIf="errorService.isError" class="alert alert-danger" role="alert" [innerHTML]=errorService.errorMessage></div>
                    <div *ngIf="errorService.isSuccess" class="alert alert-success" role="alert" [innerHTML]=errorService.successMessage></div>
                </form>
            </div>
            <br><br>
            <div class="modal-footer">
                <button class="slick-prev slick-arrow" type="button" (click)="openBackModal(depositCheckOnline)">Cancel</button>
                <button class="slick-next slick-arrow" type="submit" (click)="verifyPhoneCode(selectTypeAddFundingSource, false, verifyPhone)" [disabled]="verifyPhoneFormGroup.get('pin_part_4').errors">Submit</button>
            </div>
        </div>
        <div class="modal-body" *ngIf="isAvailablePhoneVerification()">
            <div class="modal-body">
                <app-mobile-verification-light [objExistUser]="objExistUser" [objPhoneMask]="phoneMask" [isExistUser]="isExistUser" [curentAccount]="objInvoice" [objPaymentLink]="objPaymentLink" [checkOrInvoice]="'invoice'" (nextStepAfterVerify)="moveToNextStepAfterVerify($event)"
                    (pinCodePhoneOutput)="pinCodePhone($event)" (moveToPreviousStep)="moveToPreviousStep($event)">
                </app-mobile-verification-light>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #verifyPhoneExistUser let-c="close" let-d="dismiss">
    <div class="manual-verification">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div *ngIf="!isAvailablePhoneVerification()">
            <div class="modal-body">
                <div class="manual-verification-title">
                    <h4 class="modal-title">Verify Email</h4>
                    <p class="text-center" style="padding: 0 9%;"><b>Please enter the six digit code sent to you at: {{objExistUser.email}}</b></p>
                </div>
                <form name="form" autocomplete="nope" [formGroup]="verifyPhoneFormGroup">
                    <div class="form-group verify_code_inputs text-center">
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_1" autocomplete="off" formControlName="pin_part_1" appNumbers id="pin_part_1" (input)="nextDigit(1)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_2" autocomplete="off" formControlName="pin_part_2" appNumbers id="pin_part_2" (input)="nextDigit(2)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_3" autocomplete="off" formControlName="pin_part_3" appNumbers id="pin_part_3" (input)="nextDigit(3)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_4" autocomplete="off" formControlName="pin_part_4" appNumbers id="pin_part_4" (input)="nextDigit(4)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_5" autocomplete="off" formControlName="pin_part_5" appNumbers id="pin_part_5" (input)="nextDigit(5)">&nbsp;
                        <input class="form-control text-center" maxlength="1" type="text" name="pin_part_6" autocomplete="off" formControlName="pin_part_6" appNumbers id="pin_part_6">
                        <br>
                        <br>
                        <button style="color: #1071ef!important; text-decoration: underline;" type="button" [disabled]="timerService.isTimerRun" class="btn btn-link" (click)="sendVerifyCode()">
                          Resend&nbsp;{{timerService.getRemainigTime()}}
                        </button>
                        <span style="display: none"><countdown-timer
                          *ngIf="timerService.isTimerRun"
                          [countDownTimerConfig]="timerService.configCD"></countdown-timer>
                        </span>
                    </div>
                    <div *ngIf="errorService.isError" class="alert alert-danger" role="alert" [innerHTML]=errorService.errorMessage></div>
                    <div *ngIf="errorService.isSuccess" class="alert alert-success" role="alert" [innerHTML]=errorService.successMessage></div>
                </form>
            </div>
            <div class="modal-footer">
                <button class="slick-prev slick-arrow" type="button" (click)="c('Cross click')">Cancel</button>
                <button class="slick-next slick-arrow" type="submit" *ngIf="needOnDemandBankAccount || objCurAccount.status == 'unverified' || !lstAccounts.length" (click)="verifyPhoneCode(selectTypeAddFundingSource, false, verifyPhoneExistUser)" [disabled]="verifyPhoneFormGroup.get('pin_part_4').errors">Submit</button>
                <button class="slick-next slick-arrow" type="submit" *ngIf="objCurAccount.status == 'verified' && !needOnDemandBankAccount" (click)="verifyPhoneCode(depositCheckOnline, false, verifyPhoneExistUser)" [disabled]="verifyPhoneFormGroup.get('pin_part_4').errors">Submit</button>
            </div>
        </div>
        <div class="modal-body" *ngIf="isAvailablePhoneVerification()">
            <div class="modal-body">
                <app-mobile-verification-light [objExistUser]="objExistUser" [objPhoneMask]="phoneMask" [isExistUser]="isExistUser" [curentAccount]="objCurAccount" [objPaymentLink]="objPaymentLink" [checkOrInvoice]="'invoice'" (nextStepAfterVerify)="moveToNextStepAfterVerify($event)"
                    (pinCodePhoneOutput)="pinCodePhone($event)" (moveToPreviousStep)="moveToPreviousStep($event)">
                </app-mobile-verification-light>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #settingUpAccount let-c="close" let-d="dismiss">
    <div class="manual-verification">
        <div class="modal-header">
            <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <form name="form" #f="ngForm" autocomplete="nope" class="form-validation" (ngSubmit)="signUpByInvoiceLink(seeSendConfirmationForManualVerification, settingUpAccount)">
            <div class="modal-body">
                <div class="manual-verification-title">
                    <h4 class="modal-title">{{messages.get('MANUALLY_VERIFY_YOUR_BANK_ACCOUNT')}}</h4>
                </div>
                <div class="modal-body">
                    <p class="mf-text" style="margin-bottom: 0px;" *ngIf="!objPaymentLink.thirdPartyBankVerification">
                        {{messages.get('ENTER_THE_DETAILS_OF_THE_ACCOUNT_INVOICE')}}</p>
                    <p class="mf-text" style="margin-bottom: 0px;" *ngIf="!objPaymentLink.thirdPartyBankVerification">
                        {{messages.get('TO_VERIFY_YOUR_BANK_ACCOUNT_MANUALLY')}}</p>
                    <p class="mf-text" style="margin-bottom: 0px;" *ngIf="!objPaymentLink.thirdPartyBankVerification">
                        {{messages.get('TO_AVOID_ANY_OVERDRAFT_OR_OTHER_BANK')}}</p>

                    <p class="mf-text" style="margin-bottom: 0px;" *ngIf="objPaymentLink.thirdPartyBankVerification">
                        {{messages.get('CONNECT_BA_WITH_OUT_MICRO_DEPOSITS_FULL_TEXT')}}</p>

                    <div class="row" *ngIf="objDigitCheck.accountType == 'personal'">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">First Name</label>
                                <input class="form-control" placeholder="First Name" type="text" name="firstName" required [(ngModel)]="objDigitCheck.firstName" #firstName="ngModel">
                                <div *ngIf="!firstName.valid && (firstName.dirty || firstName.touched)" class="help-block form-text with-errors form-control-feedback">First Name is required
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Last Name</label>
                                <input class="form-control" placeholder="Last Name" type="test" name="lastName" required [(ngModel)]="objDigitCheck.lastName" #lastName="ngModel">
                                <div *ngIf="!lastName.valid && (lastName.dirty || lastName.touched)" class="help-block form-text with-errors form-control-feedback">Last Name is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="objDigitCheck.accountType == 'business'">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label for="">Account Name (Optional)</label>
                                <input class="form-control" placeholder="Account Name" type="test" name="businessName" [(ngModel)]="objDigitCheck.businessName" #businessName="ngModel">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group" [ngClass]="{ 'has-error has-danger': !routingNumber.valid && (routingNumber.dirty || routingNumber.touched) }">
                                <label>Routing Number</label>
                                <input class="form-control" placement="top" ngbTooltip="Routing Number" placeholder="Routing Number" type="text" name="routingNumber" [(ngModel)]="objDigitCheck.routingNumber" #routingNumber="ngModel" required maxlength="9" minlength="9" pattern="[\*0-9]*"
                                    (change)="getBankRouting()" autocomplete="off" (keyup)="utility.onChangeExpInput($event, this.objDigitCheck.routingNumber)">
                                <div *ngIf="!routingNumber.valid && (routingNumber.dirty || routingNumber.touched)" class="help-block form-text with-errors form-control-feedback">
                                    <span *ngIf="routingNumber.errors.required">
                                        Routing Number is required
                                    </span>
                                    <span *ngIf="routingNumber.errors.minlength">
                                        Routing Number must be at least 9 digits long.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Bank Name</label>
                                <!--<div *ngIf="objDigitCheck.bank_name" class="mv-bankName" [ngClass]="{'red': objDigitCheck.error}">{{objDigitCheck.bank_name}}</div>-->
                                <div *ngIf="objDigitCheck.bank_name && objDigitCheck.error" class="mv-bankName red">
                                    {{objDigitCheck.bank_name}}</div>
                                <div *ngIf="objDigitCheck.bank_name && objDigitCheck.success" class="mv-bankName">
                                    {{objDigitCheck.bank_name}}</div>

                            </div>
                            <div class="form-group" style="display: none;">
                                <label for="">Bank Account Type</label>
                                <select class="form-control" placeholder="Bank Account Type" name="bankAccountType" required [(ngModel)]="objDigitCheck.bankAccountType" #bankAccountType="ngModel">
                                    <option value="checking" selected="true">Checking</option>
                                    <option value="saving">Saving</option>
                                </select>
                                <div *ngIf="!bankAccountType.valid && (bankAccountType.dirty || bankAccountType.touched)" class="help-block form-text with-errors form-control-feedback">Bank Account Type is required
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Account Number</label>
                                <input class="form-control" placeholder="e.g. 489729074589" type="text" name="accountNumber" required minlength="4" maxlength="17" pattern="[\*0-9]*" autocomplete="off" [(ngModel)]="objDigitCheck.accountNumber" #accountNumber="ngModel" (keyup)="utility.onChangeExpInput($event, this.objDigitCheck.accountNumber)">
                                <div *ngIf="!accountNumber.valid && (accountNumber.dirty || accountNumber.touched)" class="help-block form-text with-errors form-control-feedback">
                                    <span *ngIf="accountNumber.errors.required">
                                        Account Number is required
                                    </span>
                                    <span *ngIf="accountNumber.errors.minlength">
                                        Account Number must be at least 4 digits long.
                                    </span>
                                    <span *ngIf="accountNumber.errors.maxlength">
                                        Account Number must be at max 17 digits long.
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label for="">Confirm Account Number</label>
                                <input class="form-control" placeholder="e.g. 489729074589" type="text" name="cAccountNumber" required validateEqual="accountNumber" minlength="4" maxlength="17" pattern="[\*0-9]*" autocomplete="off" [(ngModel)]="objDigitCheck.cAccountNumber" #cAccountNumber="ngModel"
                                    (keyup)="utility.onChangeExpInput($event, this.objDigitCheck.cAccountNumber)">
                                <div *ngIf="!cAccountNumber.valid && (cAccountNumber.dirty || cAccountNumber.touched)" class="help-block form-text with-errors form-control-feedback">
                                    <span *ngIf="cAccountNumber.errors.required">
                                        Confirm Account Number is required
                                    </span>
                                    <span *ngIf="!cAccountNumber.errors.validateEqual">
                                        Account Number mismatch
                                    </span>
                                    <span *ngIf="cAccountNumber.errors.minlength">
                                        Account Number must be at least 4 digits long.
                                    </span>
                                    <span *ngIf="cAccountNumber.errors.maxlength">
                                        Account Number must be at max 17 digits long.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <img style="width: 100%;" class="brnumber-img" src="assets/img/us-bank-routing-numbers.png">
                            </div>
                        </div>
                    </div>


                    <!--<mat-spinner mode="indeterminate" diameter="50" *ngIf="isLoading" style="margin: auto;"></mat-spinner>-->
                    <div *ngIf="isLoading" class="alert alert-warning" role="alert">
                        {{messages.get('ADD_FUNDING_SOURCES_WHILE_LOADING_TEXT')}}
                    </div>
                    <div *ngIf="errorService.isError" class="alert alert-danger" role="alert" [innerHTML]=errorService.errorMessage></div>
                    <div *ngIf="errorService.isSuccess" class="alert alert-success" role="alert" [innerHTML]=errorService.successMessage></div>

                    <div *ngIf="isManualVerificationExist">
                        <div class="alert alert-warning" role="alert" [innerHTML]="messages.get('YOUR_BANK_ACCOUNT_EXIST')"></div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <button id="instantVerificationBtn" class="btn btn-primary btn-lg btn-block btn-rounded" placement="top" ngbTooltip="Instant Verification" [disabled]="isLoading" type="button" (click)="openPlaidModal(seeDepositConfirmation, settingUpAccount)">
                                        Instant Verification
                                    </button>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <button id="manualVerificationBtn" class="btn btn-primary btn-lg btn-block btn-rounded" type="button" [disabled]="isLoading" (click)="clearManualAddBankAccount(); openModal(settingUpAccount, settingUpAccount)" placement="top" ngbTooltip="Manual Verification">
                                        Manual Verification
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="isLoading" class="slick-prev slick-arrow" type="button" (click)="openBackModal(selectTypeAddFundingSource)">Cancel</button>
                <button [disabled]="isLoading || !f.form.valid" class="slick-next slick-arrow" type="submit">Submit &
                    Verify</button>
            </div>
        </form>
    </div>
</ng-template>


<ng-template #selectTypeAddFundingSource let-c="close" let-d="dismiss">
    <div class="modal-header">
        <!--<h4 class="modal-title">Add a bank account</h4>-->
        <button type="button" class="close" aria-label="Close" (click)="c('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="onboarding-media">
        <img src="assets/img/undraw-vault-9-cmw.png" srcset="assets/img/undraw-vault-9-cmw@2x.png 2x, assets/img/undraw-vault-9-cmw@3x.png 3x" class="addFundingSourceImg">
    </div>
    <div class="modal-body addFundingSourceBox">
        <div class="modal-body">
            <div class="onboarding-content with-gradient">
                <h4 class="modal-title">{{messages.get('TITLE_ADD_FUNDING_SOURCE')}}</h4>
                <p>{{messages.get('REQUEST_MONEY_ADD_AND_VERIFY_YOUR_BANK_ACCOUNT')}}</p>

                <div class="row">
                    <div class="col-sm-6 {{getCssClassForInstantVerification()}}">

                        <div class="form-group">
                            <button id="instantVerificationBtn" class="btn btn-primary btn-lg btn-block btn-rounded" placement="top" ngbTooltip="Instant Verification" [disabled]="isLoading" type="button" (click)="openPlaidModal(seeDepositConfirmation, selectTypeAddFundingSource)">
                                Instant Verification
                            </button>
                        </div>
                        <div class="form-group">
                            <label style="padding: 0 20px;">
                                {{messages.get('LOGIN_TO_YOUR_BANK_ACCOUNT')}}
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-6" *ngIf="canShowManualVerification()">
                        <div class="form-group">
                            <button id="manualVerificationBtn" class="btn btn-primary btn-lg btn-block btn-rounded" type="button" [disabled]="isLoading" (click)="openModal(settingUpAccount, selectTypeAddFundingSource)" placement="top" ngbTooltip="Manual Verification">
                                Manual Verification
                            </button>
                        </div>
                        <div class="form-group">
                            <label style="padding: 0 20px;" *ngIf="!objPaymentLink.thirdPartyBankVerification">
                                {{messages.get('HAVE_US_SEND_MICRO_DEPOSITS')}}
                            </label>
                            <label style="padding: 0 20px;" *ngIf="objPaymentLink.thirdPartyBankVerification">
                                {{messages.get('CONNECT_BA_WITH_OUT_MICRO_DEPOSITS')}}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="text-center" *ngIf="needOnDemandBankAccount">
                  I agree that future payments to {{objInFromCustComp.objPaymentLink.rec_name}} will be processed by Paynote Payment System from the selected account above. In order to cancel this authorization, I will reach out to {{objInFromCustComp.objPaymentLink.rec_name}}.
                </div>
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="errorService.isError" [innerHTML]=errorService.errorMessage></div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="slick-prev slick-arrow" type="button" [disabled]="isLoading" (click)="openBackModal(verifyPhone)">Cancel</button>
    </div>
</ng-template>
