import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {NgxPlaidLinkService, PlaidLinkHandler} from 'ngx-plaid-link';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PlaidClientService {

  public plaidCredentials: any = environment.plaid;
  public host: string = environment.host;
  public plaidHandler: PlaidLinkHandler;
  public updateLinkToken: any = null;

  constructor(
    public plaidService: NgxPlaidLinkService,
    private http: HttpClient,
  ) {}

  getUserPlaidKey(params) {
    let plaidKey = "";
    this.http
      .get<any>(this.host + "/dwl/customer/plaid/key", {
        params: params,
      })
      .subscribe((keyResponse) => {
          if (keyResponse.success) {
            this.plaidCredentials.token = keyResponse.link_token.link_token
            // plaidKey = keyResponse.key;
            // if (plaidKey !== "") {
            //   this.plaidCredentials.clientName = keyResponse.name;
            //   this.plaidCredentials.key = plaidKey;
            // }
          }
          return true;
        }
      );
  }

  getUpdateLinkToken( fs_token: string ) {
    this.http
      .get<any>(this.host + "/dwl/customer/funding-source/re-initialized", {
        params: {
          fs_token: fs_token
        },
      })
      .subscribe((res) => {
          if (res.success) {
            this.updateLinkToken = res.link_token;
          }
        }
      );
  }

  public isHaveUpdateLinkToken() {
    return this.updateLinkToken ? true : false;
  }

  public getUpdateLinkTokenForCustomer( fs_token: string, u_token: string ) {
    this.http
      .get<any>(this.host + "/dwl/customer/bank-account/re-initialized", {
        params: {
          fs_token: fs_token,
          u_token: u_token
        },
      })
      .subscribe((res) => {
          if (res.success) {
            this.updateLinkToken = res.link_token;
          }
        }
      );
  }

  async initUpdateBankAcc( vm: any ) {
    console.log( this.updateLinkToken )
    const plaidHandler=await this.plaidService.createPlaid({
      token: this.updateLinkToken,
      onSuccess: (token_1, metadata) => vm.onPlaidUpdateSuccess({
        token: token_1,
        metadata: metadata,
      }),
      onExit: (error_1, metadata_1) => vm.onPlaidExit({
        error: error_1,
        metadata: metadata_1
      })
    }).then((plaidHandler: PlaidLinkHandler) => {
      this.plaidHandler = plaidHandler;
      return this.plaidHandler.open();
    });
  }

  async init(vm, userPlaidParams, modalContent?, backContent?) {
    if( userPlaidParams ) {
      this.getUserPlaidKey(userPlaidParams);
    }    
    return this.plaidService.createPlaid(
      Object.assign({}, this.plaidCredentials, {
        onSuccess: (token, metadata) => vm.onPlaidSuccess({
          token: token,
          metadata: metadata,
          modalContent: modalContent,
          backContent: backContent
        }),
        onExit: (error, metadata) => vm.onPlaidExit({
          error: error,
          metadata: metadata
        })
      }))
      .then((plaidHandler: PlaidLinkHandler) => {
        this.plaidHandler = plaidHandler;
        return this.plaidHandler;
      });
  }
}
