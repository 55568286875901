import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../environments/environment';
import {PlaidLinkHandler} from 'ngx-plaid-link';
import {PlaidClientService} from '../../_services/plaid-client.service';
import {Spinkit} from 'ng-http-loader';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {validatePhone} from '../../_vaidators/phone';
import {validateFullName} from '../../_vaidators/fullName';
import {validateEmail} from '../../_vaidators/email';
import {Utility} from '../../_helpers/utility';
import {AuthenticationService} from '../../auth.service';
import {UserService} from '../../_services/user.service';

@Component({
  selector: 'app-capture-auth',
  templateUrl: './capture-auth.component.html',
  styleUrls: ['./capture-auth.component.css']
})
export class CaptureAuthComponent implements OnInit {

  public spinkit = Spinkit;
  public isLoading = false;
  public user = {
    name: '',
    email: '',
    phone: '',
  };
  public isError = false;
  public isSuccess = false;
  public merchant = {
    name: '',
    is_on_demand: false
  };
  public token = '';
  public paddingTop = 10;

  public host: string;
  public hostApi: string;

  public signUpForm: FormGroup;

  @ViewChild("showSuccess", { static: true })
  private showSuccess: TemplateRef<any>;

  public modalRef: NgbModalRef;

  constructor(
    private authenticationService: AuthenticationService,
    private http: HttpClient,
    private router: ActivatedRoute,
    public plaidService: PlaidClientService,
    public topAlertsService: TopAlertsService,
    private _formBuilder: FormBuilder,
    public utility: Utility,
    private modalService: NgbModal,
    private userService: UserService,
  ) {
    this.host = environment.host;
    this.hostApi = environment.api.live_endpoint;
  }

  ngOnInit(): void {
    const bodyTag = document.body;
    bodyTag.classList.add('bg-color');

    this.token = this.router.snapshot.paramMap.get('u_token');
    this.getMerchantInfo();
    this.userService.setFingerPrintBrowser();

    let form = {
      phone: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(14),
          validatePhone,
        ]),
      ],
      name: ["", Validators.compose([Validators.required, validateFullName])],
      email: ["", Validators.compose([Validators.required, validateEmail])],
    };

    this.signUpForm = this._formBuilder.group(form);
    this.plaidService.getUserPlaidKey({ u_token: this.token });
  }

  openSmallDialog(content: any) {
    this.modalRef = this.modalService.open(content);
  }

  getMerchantInfo() {
    this.http.get<any>(this.host + '/user/capture-auth/info/' + this.token)
      .subscribe(
        response => {
          if (response.success) {
            this.merchant.name = response.name;
            this.merchant.is_on_demand = response.is_on_demand;
          }
        },
        errResponse => {
          this.isLoading = false;
          if (errResponse.error) {
            this.isError = true;
          }
        }
      );
  }

  onPlaidSuccess(event) {
    const publicToken = event.token
    const objAccountInfo = event.metadata
    const Request = <any> {
      account_id: objAccountInfo.account_id,
      token: publicToken,
      fpb: this.authenticationService.getFingerPrintBrowser(),
      browser: this.authenticationService.browComponent,
    };
    Request.email = this.signUpForm.value.email;
    const arrNames = this.signUpForm.value.name.split(" ");
    Request.firstName = arrNames[0] ? arrNames[0] : "";
    Request.lastName = arrNames[1] ? arrNames.slice(1).join(" ") : "";
    Request.phone_number = this.signUpForm.value.phone;
    Request.capture_auth = true;
    Request.u_token = this.token;

    this.isLoading = true;
    this.http.post<any>(this.host + '/user/capture-auth', Request)
      .subscribe(
        response => {
          if (response.success) {
            this.plaidService.plaidHandler.exit({ force: true });
            this.signUpForm.reset();
            this.isSuccess = true;
            // this.openSmallDialog(this.showSuccess);
          }
        },
        errResponse => {
          this.isLoading = false;
          if (errResponse.error) {
            this.topAlertsService.popToast('error', 'Error', errResponse.error.message);
          }
        }
      );
  }

  onPlaidExit(event) {
    this.isLoading = false;
  }

  openPlaidModal(modalContent?, backContent?) {
    const arrNames = this.signUpForm.value.name.split(" ");
    const errorMessage = 'Please provide both a full first and last name, exactly as they appear on your bank account.';
    const firstName = arrNames[0] ? arrNames[0] : "";
    const lastName = arrNames[1] ? arrNames.slice(1).join(" ") : "";
    if (firstName.length < 2 || lastName.length < 2) {
      this.topAlertsService.popToast('error', 'Error', errorMessage);
      return;
    }
    this.isLoading = true;
    setTimeout(() => {
      this.plaidService.init(this, null, modalContent, backContent)
      .then((plaidHandler: PlaidLinkHandler) => {
        this.isLoading = false;
        plaidHandler.open();
      });
    }, 1500);
  }

  getPaddingTop(){
    return this.paddingTop + '%';
  }

}
