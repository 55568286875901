import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../auth.service';
import {UserService} from '../../_services/user.service';
import {JqueryService} from '../../_services/jquery.service';
import {environment} from '../../../environments/environment';
import {MessagesService} from '../../_services/messages.service';
import {ActivatedRoute} from '@angular/router';
import {TopAlertsService} from '../../_services/top-alerts.service';
import {Utility} from '../../_helpers/utility';
import {TimerService} from '../../_services/timer.service';

@Component({
  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  showLoginForm = true;
  show2FAForm = false;
  showResetPassword = false;
  isSuccessLogin = false;
  isQuickLogin = false;
  isSuccessResetPas = false;
  isUseLandingPhone = false;
  successMessageLogin = '';
  successMessageResetPas = '';
  isErrorResetPas = false;
  isVisibleLoginForm = true;
  isVerifyByGoogleApp = false;
  errorMessageResetPas = '';
  public wp_auth_token: string;

  constructor(
    private authenticationService: AuthenticationService,
    public userService: UserService,
    public messages: MessagesService,
    public jqueryService: JqueryService,
    private router: ActivatedRoute,
    public topAlertsService: TopAlertsService,
    public utility: Utility,
    public timerService: TimerService
  ) {}

  ngOnInit() {
    if (environment.subDomen === 'paynote' ) {
      this.authenticationService.clearLogin();
    }
    this.isQuickLogin = environment.subDomen !== 'demo' && environment.subDomen !== 'paynote' && environment.subDomen !== 'dev';
    // this.isQuickLogin = false;
    this.timerService.initTimerCD('down');
    this.wp_auth_token = this.router.snapshot.paramMap.get('wp_auth_token');
    if (this.wp_auth_token) {
      this.isVisibleLoginForm = false;
      this.loginWordpress();
    } else {
      this.checkSandBoxDemoMode();
    }
    // reset login status
    this.jqueryService.removeSelector('.modal-backdrop.fade');
    this.jqueryService.removeSelector('.modal.fade.show.d-block');
    // this.userService.setFingerPrintBrowser(); //////   deprecated  //////
  }

  checkSandBoxDemoMode() {
    if ( environment.isSandBoxMode && localStorage.getItem('currentUserV3') ) {
      this.userService.initUser();
    } else {
      this.authenticationService.logout();
    }
  }

  backToLogin() {
    this.isUseLandingPhone = false;
    this.showFormLogin();
  }

  availableSignUpByEmal() {
    return environment.availableSignUpByEmal;
  }

  login( event: any = null ) {
    let bValid = false;
    if ( event && event.key == 'Enter' && event.keyCode == 13 && this.model.password && this.model.username ) {
      bValid = true;
    }
    if ( !event && this.model.password && this.model.username ) {
      bValid = true;
    }
    if ( !bValid ) { return; }

    if ( !this.isQuickLogin ) {
      this.timerService.setTime(60, 's');
    }
    this.loading = true;
    this.authenticationService.login(this.model.username, this.model.password )
      .subscribe(
        result => {
          if ( result ) {
            if ( ( result.verify_by_google_app || result.message ) && result.success ) {
              if ( result.message && result.message.indexOf('call to your phone') ) {
                this.isUseLandingPhone = true;
              }
              this.showForm2FA();
              if( !result.verify_by_google_app ) {
                this.isVerifyByGoogleApp = false;
              } else {
                this.isVerifyByGoogleApp = true;
              }
              this.topAlertsService.popToast('info', '', result.message);

              this.loading = false;
              // if ( this.isQuickLogin ) {
              //   this.autoLogin( result.message );
              // }
            } else if ( result.success && result.token ) {
              this.initLogin();
            }
          } else {
            this.topAlertsService.popToast('error', '', this.messages.get('USERNAME_OR_PASSWORD_IS_INCORRECT'));
            this.loading = false;
          }
        },
        errResponse => {
          this.loading = false;
          if ( errResponse.error ) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  autoLogin( strMessage: string = '' ) {
    const regExp = /\(([^)]+)\)/;
    const pinCode = regExp.exec(strMessage);
    if ( pinCode[1] && pinCode[1].length === 6 ) {
      this.model.pinCode = pinCode[1];
      this.login2FA();
    }
  }

  loginWordpress() {
    this.loading = true;
    this.authenticationService.wpLogin(this.wp_auth_token)
      .subscribe(
        result => {
          if ( result ) {
            this.initLogin();
          }
        },
        errResponse => {
          this.loading = false;
          if ( errResponse.error ) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  login2FA() {
    this.loading = true;
    this.authenticationService.login2FA(this.model.username, this.model.password, this.model.pinCode)
      .subscribe(
        result => {
          if ( result ) {
            this.initLogin();
          } else {
            this.topAlertsService.popToast('error', '', this.messages.get('USERNAME_OR_PASSWORD_IS_INCORRECT'));
            this.loading = false;
          }
        },
        errResponse => {
          this.loading = false;
          if ( errResponse.error ) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  initLogin() {
    this.userService.clearIntervals();
    this.userService.lastActive = Date.now();
    this.userService.initUser(true);
    setTimeout(() => { this.userService.initReloginModule(); }, 2000 );
    this.userService.retrieveUserLimits();
  }

  showFormLogin() {
    this.showLoginForm = true;
    this.show2FAForm = false;
    this.showResetPassword = false;
  }

  showFormResetPassword() {
    this.showLoginForm = false;
    this.show2FAForm = false;
    this.showResetPassword = true;
  }

  showForm2FA() {
    this.showLoginForm = false;
    this.show2FAForm = true;
    this.showResetPassword = false;
  }

  resetPassword() {
    this.loading = true;
    this.authenticationService.recoveryPassword(this.model.emailPassword )
      .subscribe(
        result => {
          if ( result ) {
            this.isSuccessResetPas = true;
            this.successMessageResetPas = result.message;
            this.loading = false;
          }
        },
        errResponse => {
          this.loading = false;
          if ( errResponse.error ) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
            this.isErrorResetPas = true;
          }
        }
      );
  }
}
