import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../../_services/user.service';
import { environment } from '../../../environments/environment';
import { MessagesService } from '../../_services/messages.service';
import { JqueryService } from '../../_services/jquery.service';
import { Utility } from '../../_helpers/utility';
import { MatDialog } from '@angular/material/dialog';
import { BankRoutingService } from '../../_services/bank-routing.service';
import { AuthenticationService } from '../../auth.service';
import { TopAlertsService } from '../../_services/top-alerts.service';
import { UploadDoc } from '../../_helpers/upload-doc';
import { Uploader } from '../../_helpers/uploader/uploader';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { validateBirthDate } from '../../_vaidators/birthDate';
import { StatesService } from '../../_services/states.service';
import { NgProgress, NgProgressRef } from '@ngx-progressbar/core';
import { SpinnerVisibilityService, Spinkit } from 'ng-http-loader';
import { PermissionsService } from '../../_services/permissions.service';
import { validateUsaZipCode } from '../../_vaidators/zipCode';
import {PlaidLinkHandler} from 'ngx-plaid-link';
import {PlaidClientService} from '../../_services/plaid-client.service';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import {SharedService} from '../../_services/shared.service';

// declare var Plaid: any;

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.css'],
})
export class UserActionsComponent implements OnInit {
  minDate = new Date(1918, 0, 1);
  public spinkit = Spinkit;
  public host: string = environment.host;
  public hostNoSandbox: string = environment.hostWithOutSandbox;
  public loading: boolean;
  formDataDocument: any = {};
  public isUSA = true;
  public isClientNotHaveFundingSource = false;
  public lstMerchantDocuments: any = [];
  public onboardingStep: number = 0;
  public modalRef: NgbModalRef;
  public isLoading = false;
  public modelVerifyFundSource: any = {
    account: {},
  };
  public docsToUpload = {};

  public achProcessingProfile: any = {
    paymentSubmissionMethods: {
      invoicing: false,
      api: false,
      paymentLinks: false,
      onlineCheckout: false,
   },
   transactionType: null,
   solutionsRequested: {
      debit: false,
      credit: false,
      reccuring: false,
      marketplace: false,
      onlineWallet: false,
      saveDetails: false,
    },
    primary_use_case: null,
    average_transaction_size: null,
    total_monthly_volume: null
  };

  public objBeneficialOwner = {
    idBeneficialOwner: null,
    nameBeneficialOwner: null,
    emailBeneficialOwner: null,
  };

  public backButtonContent: any;
  public modelNewFundSource: any = {
    name: '',
    bankAccountType: 'checking',
    routingNumber: '',
    accountNumber: '',
    re_account_number: '',
    dwl_token: '',
  };
  objRequiredControls = {
    beneficialAddressPostalCode: true,
  };
  addBeneficialFormGroup: FormGroup;
  arrStates = this._stateService.arrStates;
  arrCountries = this._stateService.arrCountrise;
  arrCountriesPassport = this._stateService.arrCountriseWithOutUS;
  arrCitizenshipStatus = this._stateService.arrCitizenshipStatus;

  public verifyPhoneForm: FormGroup;
  public errorFileType = false;
  public errorFileSize = false;
  public objUserActionsComp: any = {
    showMoreText: <boolean>true,
  };

  public modelVerify: any = {
    pin_code: '', u_token: this.userService.getToken()
  };
  public plaidCredentials: any = environment.plaid;
  public docsFailureReasons = null;

  @ViewChild('foundingSourcesVerifiedDialog', { static: true })
  private foundingSourcesVerifiedDialog: TemplateRef<any>;

  @ViewChild('verifyVoiceCodeDialog', { static: true })
  private verifyVoiceCodeDialog: TemplateRef<any>;

  @ViewChild('verifyPhoneCodeDialog', { static: true })
  private verifyPhoneCodeDialog: TemplateRef<any>;

  @ViewChild('uploadBankStatement', { static: true })
  private uploadBankStatement: TemplateRef<any>;

  @ViewChild('uploadMerchantsDocuments', { static: true })
  private uploadMerchantsDocuments: TemplateRef<any>;

  @ViewChild('verifyEmail', { static: true })
  private verifyEmail: TemplateRef<any>;

  progressRef: NgProgressRef;

  constructor(
    private http: HttpClient,
    public userService: UserService,
    private modalService: NgbModal,
    public uploaderService: Uploader,
    public dialog: MatDialog,
    public messages: MessagesService,
    public utility: Utility,
    public jqueryService: JqueryService,
    private bankRoutingService: BankRoutingService,
    public authenticationService: AuthenticationService,
    public topAlertsService: TopAlertsService,
    private _formBuilder: FormBuilder,
    public _stateService: StatesService,
    public ngProgress: NgProgress,
    public permissionsService: PermissionsService,
    private spinner: SpinnerVisibilityService,
    public plaidService: PlaidClientService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.progressRef = this.ngProgress.ref("myProgress");

    this.plaidService.getUserPlaidKey({ u_token: this.userService.getToken() });

    this.addBeneficialFormGroup = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      ssn: ['', Validators.required],
      dateOfBirth: [
        '',
        Validators.compose([Validators.required, validateBirthDate]),
      ],
      address1: ['', Validators.required],
      address2: [''],
      dwl_owner_token: [''],
      country: ['US', Validators.required],
      stateProvinceRegion: ['', Validators.required],
      stateProvinceRegionNotUS: ['', Validators.required],
      countryPassport: ['', Validators.required],
      postalCode: ['', Validators.required],
      number: ['', Validators.required],
      city: ['', Validators.required],
      citizenshipStatus: ['', Validators.required],
    });

    this.verifyPhoneForm = this._formBuilder.group({
      phone_pin: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(6),
          Validators.maxLength(6),
        ]),
      ],
    });

    this.achProcessingProfile = this.userService.getAchProcessingProfile() || this.achProcessingProfile;

    if( this.userService.isItemLoginRequired() && this.userService.canShowPopupReSyncBA() ) {
      this.plaidService.getUpdateLinkToken( this.userService.getItemLoginRequired() );
    }
    if ( (this.userService.isAdmin() || this.userService.isSuperAdmin()) && this.userService.requiredVerifyPhone() ) {
      this.callAction({action: 'verify_phone_code'});
    }

    if (!this.userService.isEmailConfirmation() && (this.userService.isRep() || this.userService.isPartner())) {
      this.callAction({action: 'verify_email'});
    }


    this.docsFailureReasons = this.userService.getDocsFailureReasons();

    this.getOnboardingStep();

    if (this.onboardingStep === 2 && this.userService.isMerchant()) {
      setTimeout(
        () => this.goToNextStep(),
        1000
      );
    }
  }

  isDisabledNextStepFromACHProcessingProfile() {
    return !this.atLeastOneSolutionsRequestedChecked() ||
    !this.atLeastOnePaymentSubmissionMethodsChecked() ||
    !this.atLeastOneTransactionTypeChecked() ||
    !this.atLeastOnePrimaryUseCaseChecked() ||
    !this.atLeastOneAverageTransactionSizeChecked() ||
    !this.atLeastOneTotalMonthlyVolumeChecked();
  }

  atLeastOneSolutionsRequestedChecked() {
    for (const key in this.achProcessingProfile.solutionsRequested) {
      if (this.achProcessingProfile.solutionsRequested[key] == true) { return true; }
    }
    return false;
  }
  atLeastOnePaymentSubmissionMethodsChecked() {
    for (const key in this.achProcessingProfile.paymentSubmissionMethods) {
      if (this.achProcessingProfile.paymentSubmissionMethods[key] == true) { return true; }
    }
    return false;
  }
  atLeastOneTransactionTypeChecked() {
    return this.achProcessingProfile.transactionType != null &&
    this.achProcessingProfile.transactionType != '';
  }
  atLeastOnePrimaryUseCaseChecked() {
    return this.achProcessingProfile.primary_use_case != null &&
    this.achProcessingProfile.primary_use_case != '';
  }
  atLeastOneAverageTransactionSizeChecked() {
    return this.achProcessingProfile.average_transaction_size != null &&
    this.achProcessingProfile.average_transaction_size != '';
  }
  atLeastOneTotalMonthlyVolumeChecked() {
    return this.achProcessingProfile.total_monthly_volume != null &&
    this.achProcessingProfile.total_monthly_volume != '';
  }

  eventClickMoreInfo() {
    this.jqueryService.toggleWraper('#wrapp_info_need');
    this.objUserActionsComp.showMoreText =
      !this.objUserActionsComp.showMoreText;
  }

  openDialogModal(content: any, bIsBig: boolean = false) {
    if (!bIsBig) {
      this.modalRef = this.modalService.open(content);
    } else {
      this.modalRef = this.modalService.open(content, { size: 'lg' });
    }
  }

  onImageChangeFromFile(event: any = null, fileSize: number = 10485760, type: string = null) {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (
        file.type === 'application/pdf' ||
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/tif'
      ) {
        this.errorFileType = false;
      } else {
        this.errorFileType = true;
        this.topAlertsService.popToast(
          'error',
          'Error',
          'The file must be either a .jpg, .jpeg, .png or .pdf'
        );
      }
      if (file.size < fileSize) {
        this.errorFileSize = false;
      } else {
        this.errorFileSize = true;
        this.topAlertsService.popToast(
          'error',
          'Error',
          'The file size must be either up to ' +
            this.utility.formatFileSize(fileSize, 2)
        );
      }

      if (type && !this.errorFileSize && !this.errorFileType && file) {
        this.onFileChange(type, file);
      }
    }
  }

  onFileChange(customType: string = null, file = null) {
    this.loading = true;
    let uploadFile;
    if (file) {
      uploadFile = file;
    } else {
      uploadFile = (<HTMLInputElement>(
        window.document.getElementById('payNoteDocUpload')
      )).files[0];
    }

    let url = '/dwl/customer/document/create';
    if (this.objBeneficialOwner.idBeneficialOwner) {
      url = '/dwl/customer/beneficial-owner/document/create';
    }
    this.progressRef.start();
    const PayNoteUploadItem = new UploadDoc(uploadFile, this.userService, url);
    PayNoteUploadItem.formData = <any>{
      type: customType != null ? customType : this.formDataDocument.type,
      u_token: this.userService.getToken(),
    };
    if (this.objBeneficialOwner.idBeneficialOwner) {
      PayNoteUploadItem.formData.dwl_owner_token =
        this.objBeneficialOwner.idBeneficialOwner;
    }
    this.uploaderService.onSuccessUpload = (
      item,
      response,
      status,
      headers
    ) => {
      this.progressRef.complete();
      this.userService.reInitClient();
      this.loading = false;
    };
    this.uploaderService.onErrorUpload = (item, response, status, headers) => {
      this.progressRef.complete();
      this.loading = false;
      if (status === 503) {
        this.topAlertsService.popToast(
          'error',
          'Error',
          'The file must be either a .jpg, .jpeg, .png or .pdf up to ' +
            (customType != null ? '2' : '10') +
            'MB in size.'
        );
      } else {
        if (
          response.message &&
          status != 200 &&
          status != 413 &&
          status != 400
        ) {
          this.topAlertsService.popToast('error', 'Error', response.message);
        }
      }
    };
    this.uploaderService.onCompleteUpload = (
      item,
      response,
      status,
      headers
    ) => {
      this.progressRef.complete();
      this.loading = false;
      this.userService.reInitClient();
      if (!file) {
        this.closeModal();
      } else {
        this.getPartnerMerchantDocuments();
        this.callDocsRefreshFunction();
      }
      if (status === 200) {
        this.topAlertsService.popToast(
          'success',
          'Success',
          this.messages.get('DOCUMENT_UOLOADED_SUCCESSFULLY')
        );
        // this.userService.redirectJustSimple('/account/details/beneficial-upload-document');
      }
      this.objBeneficialOwner.idBeneficialOwner = null;
      this.lstMerchantDocuments = '';

      if (status === 413) {
        this.topAlertsService.popToast(
          'error',
          'Error',
          this.messages.get('DOCUMENT_UOLOADED_ERROR')
        );
      }
      if (status == 400) {
        if (response.message) {
          this.topAlertsService.popToast('error', 'Error', response.message);
        }
        if (response.messages && response.messages.length > 0) {
          this.topAlertsService.popToast(
            'error',
            'Error',
            response.messages[0]
          );
        }
      }

      scrollTo(0, 20);
    };
    this.uploaderService.upload(PayNoteUploadItem);
  }

  selectVerifiedAccountType() {
    const payload = {
      u_token: this.userService.getToken(),
      ach_processing_profile: this.achProcessingProfile
    };
    this.authenticationService.sendAchProcessingProfile(payload).subscribe(result => {
      this.userService.reInitClient(this.userService.initUser());
      this.refreshOnboardingStep(() => {
        this.jqueryService.closeModal('.ach-processing-profile-setup-modal');
        this.goToNextStep();
      });
    });
  }

  public refreshOnboardingStep(callback: any = null) {
    this.http
      .get<any>(this.host + '/user/refresh/onboarding', {
        params: { u_token: this.userService.getToken() },
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.onboardingStep = response.onboarding_step;

            if (callback) {
              callback();
            }
          }
        }
      );
  }

  public profileInfoVerifyEmail() {
    this.http
      .post<any>(this.host + '/user/email/verification', {
        email: this.userService.getEmail(),
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.topAlertsService.popToast(
              'success',
              'Success',
              response.message
            );
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              'error',
              'Error',
              this.utility.errorMessage
            );
          }
        }
      );
  }

  isShowFundingSourceSetupOnLoad() {
    return !this.userService.isHaveBankAccount() &&
      this.userService.isFilledInfoForDwolla() &&
      !this.userService.isMerchant() &&
      this.userService.isPasswordSet() &&
      this.userService.isEmailConfirmation() &&
      !this.userService.isDwollaSuspended()
      ? true
      : false;
  }

  // temporarily tern off
  isShowAdditionalVerificationCode() {
    let bIsReqAdditVerification = false;
    if (
      this.userService.isMerchant() &&
      this.userService.isAdditionalVerificationRequired()
    ) {
      bIsReqAdditVerification = true;
    }

    return bIsReqAdditVerification;
  }

  callAction(objWarMessage: any = {}) {
    switch (objWarMessage.action) {
      case 'verify_funding_source':
        this.modelVerifyFundSource = objWarMessage;
        this.openDialog(this.foundingSourcesVerifiedDialog);
        break;
      case 'verify_voice_code':
        console.log(this.modalRef);
        this.authenticationService
          .sendVerifyVoiceCode(this.userService.getToken())
          .subscribe(
            (result) => {
              this.loading = false;
              const objResp = <any>result;
              console.log(objResp);
              if (objResp.success) {
                this.topAlertsService.popToast('success', '', objResp.message);
              }
              return false;
            },
            (err) => {
              this.loading = false;
              if (err.error) {
                this.topAlertsService.popToast(
                  'error',
                  'Error',
                  err.error.message
                );
              }
            }
          );
        this.openDialog(this.verifyVoiceCodeDialog);
        break;
      case 'verify_phone_code':
        this.authenticationService
          .sendVerifyPhoneCodeForAuthUser(this.userService.getToken())
          .subscribe(
            (result) => {
              this.loading = false;
              const objResp = <any>result;
              if (objResp.success) {
                this.topAlertsService.popToast('success', '', objResp.message);
              }
              return false;
            },
            (err) => {
              this.loading = false;
              if (err.error) {
                this.topAlertsService.popToast(
                  'error',
                  'Error',
                  err.error.message
                );
              }
            }
          );
        this.openDialog(this.verifyPhoneCodeDialog, null, {backdrop: 'static'});
        break;
      case 'verify_email':
        this.authenticationService.sendVerifyCodeToEmail(this.userService.getEmail()).subscribe(
          r => {
            this.loading = false;
            const objResp = <any>r;
            if (objResp.success) {
              this.topAlertsService.popToast('success', '', objResp.message);
            }
            return false;
        },
          e => {
            this.loading = false;
            if (e.error) {
              this.topAlertsService.popToast(
                'error',
                'Error',
                e.error.message
              );
            }
        });
        this.openDialogModal(this.verifyEmail, false);
        break;
    }
  }

  verifyPhoneCode() {
    this.authenticationService
      .verifyPhoneCodeForAuthUser(
        this.userService.getToken(),
        this.verifyPhoneForm.value.phone_pin
      )
      .subscribe(
        (result) => {
          this.loading = false;
          const objResp = <any>result;
          console.log(objResp);
          if (objResp.success) {
            this.topAlertsService.popToast(
              'success',
              'Success',
              'Phone verification was successful'
            );
            this.closeModal();
            this.userService.reInitClient();
          }
          return false;
        },
        (err) => {
          this.loading = false;
          if (err.error) {
            this.topAlertsService.popToast('error', 'Error', err.error.message);
          }
        }
      );
  }

  verifyVoiceCode() {
    this.authenticationService
      .verifyVoiceCode(
        this.userService.getToken(),
        this.verifyPhoneForm.value.phone_pin
      )
      .subscribe(
        (result) => {
          this.loading = false;
          const objResp = <any>result;
          console.log(objResp);
          if (objResp.success) {
            this.topAlertsService.popToast(
              'success',
              'Success',
              'Additional verification was successful'
            );
            this.closeModal();
            this.userService.reInitClient();
          }
          return false;
        },
        (err) => {
          this.loading = false;
          if (err.error) {
            this.topAlertsService.popToast('error', 'Error', err.error.message);
          }
        }
      );
  }

  openDialog(content: any, contentBack: any = null, options: any = null) {
    this.closeModal();
    if (contentBack) {
      this.backButtonContent = contentBack;
    }
    this.modalRef = this.modalService.open(content, options);
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
  newShowClientFS() {
    this.isClientNotHaveFundingSource = true;
    this.jqueryService.showModal('.funding-source-setup-modal.modal', {
      backdrop: 'static',
      keyboard: false,
      showClose: true,
    });
  }

  verifyFundingSourcesBank() {
    this.loading = true;
    this.http
      .post<any>(this.host + '/dwl/funding-source/verify', {
        fundingsource: this.modelVerifyFundSource.data.fs_token,
        amount1: this.modelVerifyFundSource.amount1,
        amount2: this.modelVerifyFundSource.amount2,
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.loading = false;
            this.closeModal();
            this.topAlertsService.popToast(
              'success',
              'Success',
              this.messages.get('FUNDING_SOURCE_VERIFY_SUCCESSFULLY')
            );
            this.userService.reInitClient();
            setTimeout(() => this.initActionsAfterVerifyFundingSources(), 1000);
            scrollTo(0, 20);
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.loading = false;
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              'error',
              'Error',
              this.utility.errorMessage
            );
          }
        }
      );
  }

  initActionsAfterVerifyFundingSources() {
    location.reload();
  }

  closePopupForAddFoundSources(bAddClassToBody: boolean = false) {
    this.closeModal();
    this.jqueryService.closeModal('.funding-source-setup-modal.modal');
    if (bAddClassToBody) {
      setTimeout(() => this.jqueryService.addClass('body', 'modal-open'), 500);
    }
  }

  onPlaidSuccess(event) {
    const vm = this;
    const publicToken = event.token;
    const objAccountInfo = event.metadata;
    let url = '/dwl/customer/funding-source/plaid/create';
    if (vm.userService.isClient() || vm.userService.isCustomer()) {
      url = '/dwl/client/funding-source/plaid/create';
    }
    vm.http
      .post<any>(vm.host + url, {
        account_id: objAccountInfo.account_id,
        public_token: publicToken,
        u_token: vm.userService.getToken(),
      })
      .subscribe(
        (response) => {
          vm.loading = false;
          if (response.success) {
            vm.userService.reInitClient();
            vm.closePopupForAddFoundSources();
            vm.topAlertsService.popToast(
              'success',
              'Success',
              vm.messages.get('FUNDING_SOURCE_ADDED_SUCCESSFULLY')
            );
            scrollTo(0, 20);
            if (vm.userService.isMerchant()) {
              this.refreshOnboardingStep(() => {
                if (this.onboardingStep == 4) {
                  this.getPartnerMerchantDocuments(true);
                }
              });
            } else if (
              !vm.userService.isHavePlan() &&
              !vm.userService.isSubDomenSandBox() &&
              !vm.userService.isBankStatementUploaded()
            ) {
              vm.openDialog(vm.uploadBankStatement);
            }
          }
        },
        (errResponse) => {
          vm.loading = false;
          if (errResponse.error) {
            vm.loading = false;
            vm.topAlertsService.popToast(
              'error',
              'Error',
              errResponse.error.message
            );
          }
        }
      );
  }

  onPlaidExit(event) {
    this.isLoading = false;
  }

  openPlaidModal(modalContent?, backContent?) {
    this.isLoading = true;
    this.plaidService.init(this, null, modalContent, backContent)
      .then((plaidHandler: PlaidLinkHandler) => {
        this.isLoading = false;
        plaidHandler.open();
      });
  }

  showPopup(modalClass: string, focusId: string = '') {
    this.jqueryService.showModal(modalClass, {
      backdrop: 'static',
      keyboard: false,
    });
    if (focusId != '') {
      setTimeout(() => this.jqueryService.setFocus(focusId), 500);
    }
  }

  getBankRouting() {
    this.bankRoutingService
      .getBankInfo(this.modelNewFundSource.routingNumber)
      .subscribe(
        (response) => {
          if (response.success) {
            this.modelNewFundSource.name = response.bankInfo.name;
            this.modelNewFundSource.success = response.success;
            this.modelNewFundSource.error = false;
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.modelNewFundSource.name = errResponse.error.message;
            this.modelNewFundSource.error = errResponse.error.error;
            this.modelNewFundSource.success = false;
          }
        }
      );
  }

  addFundSources() {
    this.spinner.show();
    if (
      this.modelNewFundSource.accountNumber !==
      this.modelNewFundSource.re_account_number
    ) {
      this.topAlertsService.popToast(
        'error',
        '',
        this.messages.get('ACCOUNT_NUMBER_AND_RE-ENTER_ACCOUNT')
      );
      this.spinner.hide();
      return;
    }
    this.loading = true;
    this.modelNewFundSource.dwl_token = this.userService.getDwlToken();
    this.modelNewFundSource.u_token = this.userService.getToken();

    let url = '/dwl/customer/funding-source/create';
    if (this.userService.isClient() || this.userService.isCustomer()) {
      url = '/dwl/client/funding-source/create';
    }
    this.http.post<any>(this.host + url, this.modelNewFundSource).subscribe(
      (response) => {
        if (response.success) {
          this.closePopupForAddFoundSources();
          this.loading = false;
          this.topAlertsService.popToast(
            'success',
            'Success',
            this.messages.get('FUNDING_SOURCE_ADDED_SUCCESSFULLY')
          );
          this.userService.reInitClient();
          scrollTo(0, 20);
          this.spinner.hide();
          if (!this.userService.isSubDomenSandBox() && !this.userService.isBankStatementUploaded()) {
            this.openDialog(this.uploadBankStatement);
          }
        }
      },
      (errResponse) => {
        this.spinner.hide();
        this.loading = false;
        if (errResponse.error) {
          this.utility.getMessageError(errResponse.error);
          this.topAlertsService.popToast(
            'error',
            'Error',
            this.utility.errorMessage
          );
        }
      }
    );
  }
  changeCitizenshipStatus(target: any) {
    if (target == 'US Citizen') {
      this.isUSA = true;
      this.addBeneficialFormGroup.controls['ssn'].enable();
      this.addBeneficialFormGroup.controls['stateProvinceRegion'].enable();
      this.addBeneficialFormGroup.controls[
        'stateProvinceRegionNotUS'
      ].disable();
      this.addBeneficialFormGroup.controls['countryPassport'].disable();
      this.addBeneficialFormGroup.controls['number'].disable();
      this.addBeneficialFormGroup.controls['postalCode'].setValidators(
        Validators.compose([validateUsaZipCode, Validators.required])
      );
      this.objRequiredControls.beneficialAddressPostalCode = true;
    } else {
      this.isUSA = false;
      this.addBeneficialFormGroup.controls['ssn'].disable();
      this.addBeneficialFormGroup.controls['stateProvinceRegion'].disable();
      this.addBeneficialFormGroup.controls['stateProvinceRegionNotUS'].enable();
      this.addBeneficialFormGroup.controls['countryPassport'].enable();
      this.addBeneficialFormGroup.controls['number'].enable();
      this.addBeneficialFormGroup.controls['postalCode'].setValidators(
        Validators.nullValidator
      );
      this.objRequiredControls.beneficialAddressPostalCode = false;
      this.addBeneficialFormGroup.patchValue({
        countryPassport: '',
        country: '',
      });
    }
  }
  getDateFullFormat(objDate: Date) {
    if (objDate) {
      const intDay =
        objDate.getDate() > 9 ? objDate.getDate() : '0' + objDate.getDate();
      const intMonth =
        objDate.getMonth() + 1 > 9
          ? objDate.getMonth() + 1
          : '0' + (objDate.getMonth() + 1);
      const intFullYear = objDate.getFullYear();
      return intFullYear + '-' + intMonth + '-' + intDay;
    }

    return '';
  }
  addNewBeneficialOwner() {
    this.isLoading = true;
    const objRequest = <any>{
      address: {},
    };
    objRequest.dateOfBirth = this.getDateFullFormat(
      this.addBeneficialFormGroup.value.dateOfBirth
    );
    objRequest.u_token = this.userService.getToken();
    objRequest.firstName = this.addBeneficialFormGroup.value.firstName;
    objRequest.lastName = this.addBeneficialFormGroup.value.lastName;
    objRequest.address.address1 = this.addBeneficialFormGroup.value.address1;
    objRequest.address.address2 = this.addBeneficialFormGroup.value.address2;
    objRequest.address.city = this.addBeneficialFormGroup.value.city;
    objRequest.address.country = this.addBeneficialFormGroup.value.country;
    objRequest.address.postalCode =
      this.addBeneficialFormGroup.value.postalCode;
    objRequest.dwl_owner_token =
      this.addBeneficialFormGroup.value.dwl_owner_token;

    if (this.isUSA) {
      objRequest.ssn = this.addBeneficialFormGroup.value.ssn;
      objRequest.address.stateProvinceRegion =
        this.addBeneficialFormGroup.value.stateProvinceRegion;
      delete objRequest.passport;
    } else {
      delete objRequest.ssn;
      delete objRequest.address.stateProvinceRegion;
      objRequest.passport = <any>{};
      objRequest.passport.number = this.addBeneficialFormGroup.value.number;
      objRequest.passport.country =
        this.addBeneficialFormGroup.value.countryPassport;
      objRequest.address.stateProvinceRegion =
        this.addBeneficialFormGroup.value.stateProvinceRegionNotUS;
    }
    this.http
      .post<any>(
        this.host + '/dwl/customer/beneficial-owner/create',
        objRequest
      )
      .subscribe(
        (response) => {
          if (response.success) {
            this.userService.reInitClient();
            this.topAlertsService.popToast(
              'success',
              'Success',
              this.messages.get('ADD_BENEFICIAL_OWNER')
            );
            this.closeModal();
            this.isLoading = false;
            scrollTo(0, 20);
            this.userService.redirectJustSimple('/account/details');
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.isLoading = false;
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              'error',
              'Error',
              this.utility.errorMessage
            );
          }
        }
      );
  }

  isLoaderShow(flag: boolean) {
    if (flag) {
      this.spinner.show();
    }
    this.spinner.hide();
  }

  certifyBeneficialOwner() {
    this.http
      .post<any>(this.host + '/dwl/customer/beneficial-owner/certify', {
        u_token: this.userService.getToken(),
      })
      .subscribe(
        (response) => {
          if (response.success) {
            this.topAlertsService.popToast(
              'success',
              'Success',
              this.messages.get('CERTIFY_BENEFICIAL_OWNER')
            );
            this.closeModal();
            this.userService.reInitClient();
            localStorage.setItem(
              'certify-beneficial-ownership',
              <any>Date.now()
            );
            this.userService.isRequestCertifyBeneficialOwner = false;
            // setTimeout(() => window.location.reload(), 1500);
            this.isLoading = false;
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.isLoading = false;
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              'error',
              'Error',
              this.utility.errorMessage
            );
          }
        }
      );
  }

  getBeneficialOwner() {
    const url = '/dwl/customer/beneficial-owner/list';
    this.http
      .get<any>(this.hostNoSandbox + url, {
        params: { u_token: this.userService.getToken() },
      })
      .subscribe(
        (response) => {
          let bNotVerify = false;
          if (response.success) {
            const objBeneficial = this.objBeneficialOwner;
            response.list.forEach(function (item) {
              if (
                (item.verificationStatus == 'document' ||
                  item.verificationStatus == 'incomplete') &&
                !bNotVerify
              ) {
                objBeneficial.nameBeneficialOwner =
                  item.firstName + ' ' + item.lastName;
                objBeneficial.idBeneficialOwner = item.id;
                bNotVerify = true;
              }
            });
            // this.objBeneficialOwner = objBeneficial;
            console.log(this.objBeneficialOwner);
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.loading = false;
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              'error',
              'Error',
              this.utility.errorMessage
            );
          }
        }
      );
  }

  checkCanClientUpdateToMerchant() {
    if (this.userService.isMerchant()) {
      this.jqueryService.showModal('.onboarding-in-dwolla-modal', {
        backdrop: 'static',
        keyboard: false,
      });
      return;
    }

    const objRequest: any = {
      u_token: this.userService.getToken(),
      limit: 1,
      page: 1,
      'astatus[]': 'processed',
    };

    this.http
      .get<any>(this.host + '/check/client/list', { params: objRequest })
      .subscribe(
        (response) => {
          if (
            (response.success && response.list.data.length >= 1) ||
            this.userService.getClientPartner()
          ) {
            this.jqueryService.showModal('.onboarding-in-dwolla-modal', {
              backdrop: 'static',
              keyboard: false,
            });
          } else {
            this.utility.getMessageError({
              message:
                'To upgrade, please check back with us after the transaction has been processed.',
            });
            this.topAlertsService.popToast(
              'error',
              'Error',
              this.utility.errorMessage
            );
          }
        },
        (errResponse) => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              'error',
              'Error',
              this.utility.errorMessage
            );
          }
        }
      );
  }

  startUpdateBankAccount() {
    this.plaidService.initUpdateBankAcc( this );
  }

  onPlaidUpdateSuccess(token: string, data: any) {
    this.http.get<any>(this.host + '/dwl/customer/bank-account/re-initialized/success', {
       params: {fs_token: this.userService.getItemLoginRequired()},
      }).subscribe(() => {
          this.userService.reInitClient();
          this.topAlertsService.popToast(
            'success',
            'Success',
            'Your bank account has been re-connected'
          );
        },
        (errResponse) => {
          if (errResponse.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast(
              'error',
              'Error',
              this.utility.errorMessage
            );
          }
        }
      );
  }

  openMatDialog(objDataDialog: any) {
    return this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: objDataDialog,
    });
  }

  goToNextStep() {
    this.closeModal();
    switch (this.onboardingStep) {
      case 2:
        this.jqueryService.showModal('.ach-processing-profile-setup-modal', {
          backdrop: 'static',
          width: 750,
          keyboard: false,
          showClose: true,
        });
        break;
      case 3:
        this.jqueryService.showModal('.funding-source-setup-modal', {
          backdrop: 'static',
          keyboard: false,
          showClose: true,
        });
        break;
      case 4:
        this.getPartnerMerchantDocuments(true);
        break;
      default:
        break;
    }
  }

  getOnboardingStep() {
    this.onboardingStep = this.userService.getOnboardingStep();
  }

  showOnboardingSteps() {
    return (this.userService.isMerchant() || this.userService.isGhostLogin) && this.userService.isPasswordSet()
      && this.userService.getOnboardingStep() < 5;
  }

  getPartnerMerchantDocuments(openModal = true) {
    const objRequest = <any> {
      dwl_token: this.userService.getDwlToken(),
    };
    this.http.get<any>(this.host + '/merchant/users/documents', {params: objRequest})
      .subscribe(
        response => {
          if (response.success) {
            this.docsToUpload = <any> response.list;
            if (openModal) {
              this.openDialog(this.uploadMerchantsDocuments);
            }
          }
        },
        errResponse => {
          if (errResponse.error.error) {
            this.utility.getMessageError(errResponse.error);
            this.topAlertsService.popToast('error', 'Error', this.utility.errorMessage);
          }
        }
      );
  }

  callDocsRefreshFunction(): void {
    this.sharedService.triggerEvent();
  }


  verify2FaCode( event: any = null ) {

    let bValid = false;
    if ( event && event.key == 'Enter' && event.keyCode == 13 && this.modelVerify.pin_code.length == 6 ) {
      bValid = true;
    }

    if ( event && !bValid ) { return; }

    this.isLoading = true;
      this.authenticationService.verifyEmailCode( this.userService.getEmail(), this.modelVerify.pin_code )
        .subscribe(
          result => {
            if (result) {
              this.isLoading = false;
              this.closeModal();
            }
          },
          (err) => { if (err.error) { this.topAlertsService.popToast('error', 'Error', err.error); }}
        );
  }

}
